/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import Overlay from '@scandipwa/scandipwa/src/component/Overlay';
import Popup from '@scandipwa/scandipwa/src/component/Popup';

import IfStore from 'Component/IfStore';
import { CATEGORY_ID_ANGOLO_DELLE_OCCASIONI, ID_POPUP } from 'Route/AngoloDelleOccasioni/AngoloDelleOccasioni.config';
import {
    ProductPriceComponent as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';
import { startMobile } from 'Util/mcMobileCheck';

import './ProductPrice.override.style';

/** @namespace Pwa/Component/ProductPrice/Component/ProductPriceComponent */
export class ProductPriceComponent extends SourceProductPrice {
    renderCurrentPrice() {
        const {
            discountPercentage,
            price: {
                finalPrice:{
                    value: finalPriceValue,
                }
            },
            reverse
        } = this.props;
        const priceSchema = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        // force unequal comparatment - unsure of resulting type
        // eslint-disable-next-line eqeqeq
        if (finalPriceValue == 0) {
            return null;
        }

        const {
            showOverlay, hideOverlay, showPopup, categories, id
        } = this.props;

        const isADO = categories
        && categories.find((elem) => elem.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI) !== undefined;

        const id_popup = `${ID_POPUP }_${id}`;

        return (
            <PriceSemanticElementName
              block="ProductPrice"
              elem="CurrentPrice"
              mods={ { isReverse: reverse } }
            >
                <span { ...priceSchema }>
                    <span className="currency">
                        €
                        { isADO && (
                                <button
                                  onMouseEnter={ () => {
                                      if (!startMobile()) {
                                          showOverlay(id_popup);
                                      }
                                  } }
                                  onMouseLeave={ () => {
                                      if (!startMobile()) {
                                          hideOverlay();
                                      }
                                  } }
                                  onClick={ () => {
                                      if (startMobile()) {
                                          showPopup(id_popup);
                                      }
                                  } }
                                  className={ `overlayButton ${reverse ? 'isReverse' : ''}` }
                                >
                                <div className="box">
                                    <i aria-hidden="true" className="fa fa-info-circle" />
                                </div>
                                { this.renderPopup() }
                                </button>
                        ) }
                    </span>
                    { Math.ceil(finalPriceValue) }
                </span>
            </PriceSemanticElementName>
        );
    }

    renderOldPrice() {
        const {
            discountPercentage,
            isSchemaRequired,
            variantsCount,
            reverse,
            price:{
                miglior_prezzo,
                originalPrice:{
                    value: originalPriceValue
                }
            }

        } = this.props;

        const schema = isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : {};
        if (discountPercentage === 0) {
            return null;
        }
        const delClass = miglior_prezzo === '1' ? 'miglior_prezzo' : '';
        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              mods={ { isReverse: reverse } }
              aria-label={ __('Old product price') }
              { ...schema }
              className={ delClass }
            >
                <span className="currency">€</span>
                { Math.ceil(originalPriceValue) }
            </del>
        );
    }

    renderPriceByOrder() {
        const {
            promoPosition, price, reverse
        } = this.props;

        // const delTag = price.miglior_prezzo !== '1' ? 'miglior_prezzo' : '';
        // if (reverse) {
        //     return (
        //         <>
        //             {/* { this.renderOldPrice() } */}
        //             {/* { this.renderOldPrice() } */}
        //             { this.renderCurrentPrice() }
        //         </>
        //     );
        // }

        return (
            <>
            <IfStore storeCode="default">
                { price.miglior_prezzo !== '1' && !reverse && (
                    <>
                    { this.renderCurrentPrice() }
                    { this.renderOldPrice() }
                    </>
                ) }

                { price.miglior_prezzo !== '1' && reverse && (
                    <>
                        { this.renderOldPrice() }
                        { this.renderCurrentPrice() }
                    </>
                ) }

                { price.miglior_prezzo === '1' && (
                <div className={ promoPosition }>
                    { this.renderCurrentPrice() }
                    { this.renderMigliorPrezzo() }
                </div>
                ) }
            </IfStore>
            <IfStore storeCode="spain">
                { this.renderCurrentPrice() }
                { this.renderOldPrice() }
            </IfStore>
            </>
        );
    }

    renderPopup() {
        const isMobile = startMobile();
        const { id } = this.props;
        const id_popup = `${ID_POPUP }_${id}`;

        if (isMobile) {
            return (
                <Popup
                  id={ id_popup }
                >
                    { __('ADO.price.popup') }
                </Popup>
            );
        }

        return (
            <Overlay
              size="small"
              id={ id_popup }
            >
                    { __('ADO.price.popup') }
            </Overlay>
        );
    }

    renderMigliorPrezzo() {
        const {
            price
        } = this.props;

        if (price.miglior_prezzo === '1') {
            return (
                <img
                  src="/media/promo/388.png"
                  alt="Miglior Prezzo"
                  className="miglior-prezzo-image"
                />
            );
        }

        return null;
    }

    render() {

        const {
            price: {
                finalPrice,
                originalPrice,
            } = {},
            discountPercentage,
            formattedFinalPrice,
            mix
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        

        return (
            <p
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0 } }
              mix={ mix }
              aria-label={ `Product price: ${formattedFinalPrice}` }
            >
                { this.renderPriceByOrder() }

            </p>
        );
    }
}

export default ProductPriceComponent;
