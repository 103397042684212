/* eslint-disable no-unused-vars */
import { hideActiveOverlay, toggleOverlayByKey } from '@scandipwa/scandipwa/src/store/Overlay/Overlay.action';
import { showPopup } from '@scandipwa/scandipwa/src/store/Popup/Popup.action';
import { connect } from 'react-redux';

import { ID_POPUP } from 'Route/AngoloDelleOccasioni/AngoloDelleOccasioni.config';
import { DisplayProductPricesInCatalog } from '@scandipwa/scandipwa/src/component/ProductPrice/ProductPrice.config';
import {
    mapDispatchToProps as sourceMapDisaptchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer
} from 'SourceComponent/ProductPrice/ProductPrice.container';
import {
    calculateFinalPrice,
    formatPrice
} from 'Util/Price';

/** @namespace Pwa/Component/ProductPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Pwa/Component/ProductPrice/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDisaptchToProps(),
    showOverlay: (id_popup) => dispatch(toggleOverlayByKey(id_popup)),
    hideOverlay: () => dispatch(hideActiveOverlay()),
    showPopup: (id) => dispatch(showPopup(id))
});

/** @namespace Pwa/Component/ProductPrice/Container/customFormatPrice */
export const customFormatPrice = (price, currency = 'USD') => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;
    return new Intl.NumberFormat(
        language,
        { minimumIntegerDigits: 2, maximumFractionDigits: 0, minimumFractionDigits: 0 }
    ).format(price);
};

/** @namespace Pwa/Component/ProductPrice/Container/ProductPriceContainer */
export class ProductPriceContainer extends SourceProductPriceContainer {

    getRoundedRegularPrice() {
        const {
            price: {
                minimum_price: {
                    regular_price: {
                        value: regularPriceValue
                    } = {},
                    regular_price_excl_tax: {
                        value: regularPriceExclTaxValue
                    } = {}
                } = {}
            } = {},
            displayTaxInPrice
        } = this.props;

        if (displayTaxInPrice === DisplayProductPricesInCatalog.DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
            return customFormatPrice(regularPriceExclTaxValue);
        }

        return customFormatPrice(regularPriceValue);
    }

    getFormattedFinalPrice() {
        const {
            price: {
                minimum_price: {
                    discount: {
                        percent_off: discountPercentage
                    } = {},
                    final_price: {
                        value: minimalPriceValue,
                        currency: priceCurrency
                    } = {},
                    final_price_excl_tax: {
                        value: minimalPriceExclTaxValue
                    } = {},
                    regular_price: {
                        value: regularPriceValue
                    } = {},
                    regular_price_excl_tax: {
                        value: regularPriceExclTaxValue
                    } = {}
                } = {}
            } = {},
            displayTaxInPrice
        } = this.props;

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
            const finalPrice = calculateFinalPrice(
                discountPercentage,
                minimalPriceExclTaxValue,
                regularPriceExclTaxValue
            );

            return customFormatPrice(finalPrice, priceCurrency);
        }
        
        const finalPrice = calculateFinalPrice(discountPercentage, minimalPriceValue, regularPriceValue);

        return customFormatPrice(finalPrice, priceCurrency);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
